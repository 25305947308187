@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  display: inline-block;
  color: white;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #f0f0f0;
  transform: scale(1.1);
  border-radius: 8px;
}
